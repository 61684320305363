import React from "react"
import styled from "styled-components"
import left from "../../images/frame/left.png"
import right from "../../images/frame/right.png"
import top from "../../images/frame/top.png"
import bottom from "../../images/frame/bottom.png"

const Borders = styled.div`
  box-sizing: border-box;
  div {
    position: fixed;
    background-size: 100% 100%;
    z-index: 3000;
  }
`
const BorderTop = styled.div`
  background-image: url(${top});
  top: 0;
  width: 100%;
  height: 40px;
  z-index:1 !important;
  @media(max-width:769px) {
    height:20px;
  }
`
const BorderRight = styled.div`
  background-image: url(${right});
  right: 0;
  width: 40px;
  height: 100%;
  @media(max-width:769px) {
    width:20px;
  }
`
const BorderBottom = styled.div`
  background-image: url(${bottom});
  bottom: 0;
  width: 100%;
  height: 40px;
  @media(max-width:769px) {
    height:20px;
  }
`
const BorderLeft = styled.div`
  background-image: url(${left});
  left: 0;
  width: 40px;
  height: 100%;
  @media(max-width:769px) {
    width:20px;
  }
`

export default function renderBorders() {
  return (
    <Borders>
      <BorderTop />
      <BorderRight />
      <BorderBottom />
      <BorderLeft />
    </Borders>
  )
}
