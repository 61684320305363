import { useStaticQuery, graphql } from "gatsby"
export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
    {
      site {
        siteMetadata {
          title
          url
        }
      }
    }   
    `
  )
  return site.siteMetadata
}